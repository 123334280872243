<template>
    <FormWrapper>
        <PageTitle title="Add/Update Staff Document" slot="title" link="/helpContent/Menu Detail" />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-2">
                    <FormSelect label="User Type" :items="userTypeList" item-name="name" item-value="id" v-model="form.selectedUserTypeId" :onChange="getStaffList" rules="required"/>
                </div>
                <div class="col-sm-2">
                    <FormSelect label="Select Staff" :items="staffList" item-name="Name" item-value="id" v-model="form.selectedStaffId" rules="required"/>
                </div>
                <div class="col-sm-2">
                    <FormSelect label="Document Class" rules="required" :items="documentClassList" item-name="DocumentClass" item-value="id" v-model="form.selectedDocumentClassId" :onChange="getdocumentCategoryList"/>
                </div>
                <div class="col-sm-3">
                    <FormSelect label="Document Category" :items="documentCategoryList" item-name="DocumentCategory" item-value="id" v-model="form.selectedDocumentCategoryId" :onChange="getdocumentTypeList" rules="required"/>
                </div>
                <div class="col-sm-3">
                    <FormSelect label="Document Type" :items="documentTypeList" item-name="Name" item-value="id" v-model="form.selectedDocumentTypeId" rules="required"/>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <div :class="$style.imageFile">
                        <div :class="$style.imageFileLabel">
                            <span>Document</span>
                        </div>
                        <div :class="$style.imageFileInput">
                            <InputFile :rules="{uploadRequired:false, docCheck:'pdf,jpg,jpeg,png', checkFileRegex: true}" :value="form.documentFile" name="Icon" label="Choose File" @onChange="uploadFileHandler"/>
                            <p :style="{color:'grey'}">File type can be PDF or Image</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <InputText v-model="form.documentName" label="Document Name" rules="required"  />
                </div>
                <div class="col-sm-4">
                    <inputTextArea label="Notes" v-model="form.notes" />
                </div>
            </FormRow>
            <br>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import InputFile from "Components/form/InputFile";
    import {getAllStaff, getAllActiveDeactiveStaff, getDocumentClass, documentCategory, getAllDocumentType,addUpdateStaffDocument} from '../api';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "Add",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            InputFile,
            PageTitle,
            ValidationObserver
        },
        data() {
            return {
                showMessage:{
                    isVisible: false,
                    message: ''
                },
                form:{
                    selectedUserTypeId: null,
                    selectedStaffId: null,
                    selectedDocumentClassId: null,
                    selectedDocumentCategoryId: null,
                    selectedDocumentTypeId: null,
                    documentName: '',
                    documentFile:null,
                    notes: ''
                },
                userTypeList:[
                    {
                        'id': 'All',
                        'name': 'All Users'
                    },
                    {
                        'id': 'Active',
                        'name': 'Active Users'
                    },
                    {
                        'id': 'Deactive',
                        'name': 'Deactive Users'
                    }
                ],
                staffList: [],
                documentClassList: [],
                documentCategoryList: [],
                documentTypeList:[],
                allStaffList: [],
                activeStaffList: [],
                mainFolderName: '',
                documentClassName: '',
                documentCategoryName: '',
                documentTypeName: ''
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getAllList();
        },
        methods: {
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        
                        for (let i = 0; i < this.staffList.length; i++) {
                            if (this.staffList[i].id === this.form.selectedStaffId) {
                                this.mainFolderName = this.staffList[i].EmpCode + '_' + this.staffList[i].FirstName;
                            }
                        }

                        for (let i = 0; i < this.documentClassList.length; i++) {
                            if (this.documentClassList[i].id === this.form.selectedDocumentClassId) {
                                this.documentClassName = this.documentClassList[i].DocumentClass;
                            }
                        }

                        for (let i = 0; i < this.documentCategoryList.length; i++) {
                            if (this.documentCategoryList[i].id === this.form.selectedDocumentCategoryId) {
                                this.documentCategoryName = this.documentCategoryList[i].DocumentCategory;
                            }
                        }

                        for (let i = 0; i < this.documentTypeList.length; i++) {
                            if (this.documentTypeList[i].id === this.form.selectedDocumentTypeId) {
                                this.documentTypeName = this.documentTypeList[i].Name.split(' ').join('_');
                            }
                        }
                        
                        const formData = new FormData();
                        formData.append('documentId', this.$route.params.id?atob(this.$route.params.id):0);
                        formData.append('staffId', this.form.selectedStaffId);
                        formData.append('documentClassName', this.documentClassName);
                        formData.append('documentCategoryId', this.form.selectedDocumentCategoryId);
                        formData.append('documentCategoryName', this.documentCategoryName);
                        formData.append('documentTypeId', this.form.selectedDocumentTypeId);
                        formData.append('documentTypeName', this.documentTypeName);
                        formData.append('documentName', this.form.documentName);
                        formData.append('documentFile', this.form.documentFile);
                        formData.append("mainFolderName", this.mainFolderName);
                        formData.append('notes', this.form.notes);

                        addUpdateStaffDocument(formData).then((res)=>{
                            this.form = {};
                            this.form.documentFile = null;
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            async getAllList() {
                this.form.selectedUserTypeId= "All";
                await getAllActiveDeactiveStaff().then((res)=>{
                    this.staffList=res.data
                });
                getDocumentClass().then((res) => {
                    this.documentClassList = res.data
                });
            },
            async getStaffList(ele) {
                if (ele != undefined) {
                    this.form.selectedUserTypeId=ele;
                }
                if(this.form.selectedUserTypeId){
                    if (this.form.selectedUserTypeId === "All") {
                        getAllActiveDeactiveStaff().then((res)=>{
                            this.staffList=res.data
                        });
                    } else if (this.form.selectedUserTypeId === "Active") {
                        getAllStaff().then((res)=>{
                            this.staffList=res.data
                        });
                    } else if (this.form.selectedUserTypeId === "Deactive") {
                        await getAllActiveDeactiveStaff().then((res)=>{
                            this.allStaffList=res.data;
                        });
                        
                        await getAllStaff().then((res)=>{
                            this.activeStaffList = res.data;
                        });

                        let activeselectedStaffIds = [];
                        for (let j = 0; j < this.activeStaffList.length; j++) {
                            activeselectedStaffIds.push(this.activeStaffList[j].id);
                        }

                        this.staffList = [];
                        this.allStaffList.forEach((item) => {
                            if (activeselectedStaffIds.indexOf(item.id) == -1) {
                                this.staffList.push(item);
                            }
                        });
                    }
                }
            },
            getdocumentCategoryList(ele){
                if (ele == undefined) {
                    this.form.selectedDocumentClassId=null;
                } else{
                    this.form.selectedDocumentClassId=ele;
                }
                const data = {
                    documentClassId: this.form.selectedDocumentClassId,
                };
                documentCategory(data).then((res) => {
                    this.documentCategoryList = res.data
                });
            },
            getdocumentTypeList(ele){
                if (ele == undefined) {
                    this.form.selectedDocumentCategoryId=null;
                } else{
                    this.form.selectedDocumentCategoryId=ele;
                }
                let data={
                    documentCategoryId: this.form.selectedDocumentCategoryId
                }
                getAllDocumentType(data).then((res)=>{
                    this.documentTypeList=res.data
                });
            },
            uploadFileHandler(file) {
                this.form.documentFile = file;
            },
        }
    }
</script>
<style lang="scss" module>
    .imageFile {
        display: flex;
        padding: 5px 0;
    }

    .imageFileLabel {
        width: 120px;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        display: flex;
    }

    .imageFileInput {
        flex-grow: 1;
    }

    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
</style>